import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Dashboard/Home.vue";
import Login from "../views/Login/Login.vue";
import MeuPerfil from "../views/Perfil/MeuPerfil.vue";
import DadosCadastrais from "../views/Perfil/DadosCadastrais.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/checkout",
    name: "checkout",
    props: true,
    component: () =>
      import(/* webpackChunkName: "cursaddo" */ "../views/Checkout/Index.vue")
  },
  {
    path: "/checkout/finalizado",
    name: "checkout_finalizado",
    props: true,
    component: () =>
      import(/* webpackChunkName: "cursaddo" */ "../views/Checkout/Finalizado.vue")
  },
  {
    path: "/checkout/finalizado/boleto/:codigo",
    name: "checkout_boleto",
    props: true,
    component: () =>
      import(/* webpackChunkName: "cursaddo" */ "../views/Checkout/FinalizadoBoleto.vue")
  },
  {
    path: "/checkout/finalizado/pix/:codigo",
    name: "checkout_pix",
    props: true,
    component: () =>
      import(/* webpackChunkName: "cursaddo" */ "../views/Checkout/Pix.vue")
  },
  {
    path: "/", 
    name: "Dashboard",
    component: Home,
    meta: {
      login: true, 
    },
  },
  {
    path: "/chamados",
    name: "chamados",
    component: () =>
      import(/* webpackChunkName: "relatorios" */ "../views/chamados/Index.vue"),
    meta: {
      login: true,
    },
  },
  {
    path: "/relatorios",
    name: "relatorios",
    component: () =>
      import(/* webpackChunkName: "relatorios" */ "../views/relatorios/Index.vue"),
    meta: {
      login: true,
      role: 'Gestor'
    },
  },
  {
    path: "/relatorios",
    name: "relatoriosuc",
    component: () =>
      import(/* webpackChunkName: "relatorios" */ "../views/relatorios/Indexuc.vue"),
    meta: {
      login: true,
      role: 'Gestor'
    },
  },
  {
    path: "/categorias",
    name: "editcategoriascursos",
    component: () =>
      import(/* webpackChunkName: "relatorios" */ "../views/relatorios/EditCategoriasCursos.vue"),
    meta: {
      login: true,
      role: 'Gestor'
    },
  },
  {
    path: "/compartilhar/cursos",
    name: "myshare",
    component: () =>
      import(/* webpackChunkName: "relatorios" */ "../views/relatorios/Compartilhar.vue"),
    meta: {
      login: true,
      role: 'Gestor'
    },
  }, 
  {
    path: "/cursos/compartilhados",
    name: "share",
    component: () =>
      import(/* webpackChunkName: "relatorios" */ "../views/relatorios/Compartilhados.vue"),
    meta: {
      login: true,
      role: 'Gestor'
    },
  },
  {
    path: "/cursos/solicitados",
    name: "solicitacoes",
    component: () =>
      import(/* webpackChunkName: "relatorios" */ "../views/relatorios/Solicitacoes.vue"),
    meta: {
      login: true,
      role: 'Gestor'
    },
  },
  {
    path: "/cursos/adquiridos",
    name: "adquiridos",
    component: () =>
      import(/* webpackChunkName: "relatorios" */ "../views/relatorios/Adquiridos.vue"),
    meta: {
      login: true,
      role: 'Gestor'
    },
  },
  { 
    path: "/pedidos",
    name: "pedidos",
    component: () =>
      import(/* webpackChunkName: "relatorios" */ "../views/relatorios/Pedidos.vue"),
    meta: {
      login: true,
      role: 'Gestor'
    },
  },
  {
    path: "/compartilhados",
    name: "mycompartilhados",
    component: () =>
      import(/* webpackChunkName: "relatorios" */ "../views/relatorios/MyCompartilhados.vue"),
    meta: {
      login: true,
      role: 'Gestor'
    },
  },
  {
    path: "/modelo/certificado",
    name: "modelo-certificado",
    component: () =>
      import(/* webpackChunkName: "relatorios" */ "../views/relatorios/Certificado.vue"),
    meta: {
      login: true,
      role: 'Gestor'
    },
  },
  {
    path: "/modelo_certificado",
    name: "modelo-certificado",
    component: () =>
      import(/* webpackChunkName: "relatorios" */ "../views/relatorios/Certificado.vue"),
    meta: {
      role: 'Gestor'
    },
  },
  {
    path: "/vouchers",
    name: "vouchers",
    component: () =>
      import(/* webpackChunkName: "relatorios" */ "../views/relatorios/Vouchers.vue"),
    meta: {
      role: 'Gestor'
    },
  },
  {
    path: "/cupons",
    name: "cupons",
    component: () =>
      import(/* webpackChunkName: "cupons" */ "../views/Cupons/Index.vue"),
    meta: {
      login: true,
      role: 'Gestor'
    },
  }, 
  {
    path: "/meus-cursos",
    name: "meuscursos",
    component: () =>
      import(/* webpackChunkName: "meuscursos" */ "../views/MeusCursos/MeusCursos.vue"),
    meta: {
      login: true,
      role: 'Aluno'
    },
  },
  {
    path: "/obrigado/:id",
    name: "obrigado",
    props: {
      default: true,
      dataset: true
    },
    component: () =>
      import(/* webpackChunkName: "obrigado" */ "../views/Curso/Obrigado.vue"),
    meta: {
      login: true,
    },
  },
  {
    path: "/emitir-boleto/:id",
    name: "obrigadoboleto",
    props: true,
    component: () =>
      import(/* webpackChunkName: "obrigado" */ "../views/Curso/ObrigadoBoleto.vue"),
    meta: {
      login: true,
    },
  },
  {
    path: "/matriculado",
    name: "obrigadofree",
    props: true,
    component: () =>
      import(/* webpackChunkName: "obrigado" */ "../views/Curso/ObrigadoFree.vue"),
    meta: {
      login: true,
    },
  },
  {
    path: "/certificados",
    name: "certificados",
    component: () =>
      import(/* webpackChunkName: "certificados" */ "../views/Certificados/Certificados.vue"),
    meta: {
      login: true, 
      role: 'Aluno'
    },
  },
  {
    path: "/certificado",
    name: "certificado",
    component: () =>
      import(/* webpackChunkName: "relatorios" */ "../views/Certificados/Certificado.vue"),
    meta: {
      //login: true,
    },
  }, 
  {
    path: "/certificado/:chave",
    name: "certificado",
    component: () =>
      import(/* webpackChunkName: "relatorios" */ "../views/Certificados/Certificado.vue"),
    meta: {
      //login: true,
    },
  },
  {
    path: "/curso-view/:id",
    name: "cursoview",
    component: () =>
      import(/* webpackChunkName: "view" */ "../views/Curso/CursoView.vue"),
    meta: {
      login: true,
      role: 'Aluno'
    },
  },
  {
    path: "/ajuda",
    name: "ajuda",
    component: () =>
      import(/* webpackChunkName: "ajuda" */ "../views/Ajuda/Ajuda.vue"),
    meta: {
      login: true,
    },
  },
  {
    path: "/curso/:id",
    name: "curso",
    props: true,
    component: () =>
      import(/* webpackChunkName: "curso" */ "../views/Curso/Detalhe.vue"),
    meta: {
      login: true,
    },
  },
  {
    path: "/curso-professor/:id",
    name: "cursoprofessor",
    props: true,
    component: () =>
      import(/* webpackChunkName: "curso" */ "../views/Curso/DetalheProfessor.vue"),
    meta: {
      login: true,
    },
  },
  {
    path: '/curso-professor/:idcurso/novo-quiz/:id',
    name: 'Novo Quiz',
    component: () => import('../views/Curso/CriaQuiz.vue'),
    meta: {
      requiresAuth: true,
      permissoes: [{
        role: 'aluno',
        acesso: false
      },
      {
        role: 'professor',
        acesso: true
      }]
  }
  },
  {
    path: '/curso-professor/:idcurso/editar-quiz/:id',
    name: 'editarQuiz',
    component: () => import('../views/Curso/EditarQuiz.vue'),
    meta: {
      requiresAuth: true,
      permissoes: [{
        role: 'aluno',
        acesso: false
      },
      {
        role: 'professor',
        acesso: true
      }]
  }
  },
  {
    path: '/curso-professor/:idcurso/nova-aula/:id',
    name: 'criarAula',
    component: () => import('../views/Curso/CriarAula.vue'),
    meta: {
      requiresAuth: true,
      permissoes: [{
        role: 'aluno',
        acesso: false
      },
      {
        role: 'professor',
        acesso: true
      }]
  }
  },
  {
    path: "/add-curso",
    name: "addcurso",
    component: () =>
      import(/* webpackChunkName: "cursaddo" */ "../views/Curso/AddCurso.vue"),
    meta: {
      login: true,
    },
  },
  {
    path: "/editar-curso/:id",
    name: "editarCurso",
    props: true,
    component: () =>
      import(/* webpackChunkName: "cursaddo" */ "../views/Curso/EditarCurso.vue"),
    meta: {
      login: true,
    },
  },
  {
    path: "/recuperar/:token",
    name: "recuperar",
    component: () =>
      import(
        /* webpackChunkName: "configuracoes" */ "../views/Login/Recuperar.vue"
      ),
      props: true,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    props: true,
  },
  {
    path: "/cadastre-se",
    name: "cadastro",
    component: () =>
    import(
      /* webpackChunkName: "cadastro" */ "../views/Login/Cadastre.vue"
    ),
    props: true,
  },
  {
    path: "/meu-perfil",
    name: "perfil",
    component: MeuPerfil,
    props: true,
    meta: {
      login: true,
    },
    children: [
      {
        path: "",
        name: "meuperfil",
        component: DadosCadastrais,
      },
      {
        path: "compras",
        name: "compras",
        meta: {
          role: 'Aluno',
        },
        component: () =>
        import(
          /* webpackChunkName: "compras" */ "../views/Perfil/Compras.vue"
        ),
      },
      {
        path: "configuracoes",
        name: "configuracoes",
        meta: {
          role: 'Aluno',
        },
        component: () =>
          import(
            /* webpackChunkName: "configuracoes" */ "../views/Perfil/Configuracoes.vue"
          ),
      },
	  {
        path: "plataforma",
        name: "plataforma",
        meta: {
          role: 'Gestor',
        },
        component: () =>
          import(
            /* webpackChunkName: "configuracoes" */ "../views/Perfil/Plataforma.vue"
          ),
      },
      {
        path: "script",
        name: "script",
        meta: {
          role: 'Gestor',
        },
        component: () =>
          import(
            /* webpackChunkName: "configuracoes" */ "../views/Perfil/Script.vue"
          ),
      },
      {
        path: "slide",
        name: "slide",
        meta: {
          role: 'Gestor',
        },
        component: () =>
          import(
            /* webpackChunkName: "configuracoes" */ "../views/Perfil/Slide.vue"
          ),
      },
      {
        path: "pagarme",
        name: "Pagarme",
        meta: {
          role: 'Gestor',
        },
        component: () =>
          import(
            /* webpackChunkName: "configuracoes" */ "../views/Perfil/Pagarme.vue"
          ),
      },
    ],
  },
  {
    path: "/professores",
    name: "Professores",
    component: () =>
      import(/* webpackChunkName: "cupons" */ "../views/Professores/Index.vue"),
    meta: {
      login: true,
      role: 'Gestor'
    },
  },
  {
    path: "/add-professor",
    name: "addprofessor",
    component: () =>
      import(/* webpackChunkName: "cursaddo" */ "../views/Professores/AddProfessor.vue"),
    meta: {
      login: true,
      role: 'Gestor'
    },
  },
  {
    path: "/editar-professor/:id",
    name: "editarprofessor",
    props: true,
    component: () =>
      import(/* webpackChunkName: "cursaddo" */ "../views/Professores/EditarProfessor.vue"),
    meta: {
      login: true,
    },
  },
  {
    path: "/chamado/:protocol",
    name: "chamado",
    component: () =>
      import("../views/Site/chamado.vue"),
  },

  {
    path: "/chamado",
    name: "chamadoseach",
    component: () =>
      import("../views/Site/chamado.vue"),
  }

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return window.scrollTo({ top: 0, behavior: "smooth" });
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.login)) {
    if (!window.sessionStorage.token) {
      next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});


export default router;
